import { useState } from 'react'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { Image } from '../types/image';

import {
    Card,
    Stack,
    Typography,
    Button,
    CardMedia,
    Divider,
    Container
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ImageUploading from 'react-images-uploading';

import { api } from '../api'

import { toast } from 'react-hot-toast';

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import PageLoader from '../components/loader'

export default function OptionPage(props: any) {
    const userLoading: boolean = false;
    return (

    <>
        {userLoading ? <PageLoader /> : <>
            <Helmet>
                <title> Option | Lizaora </title>
            </Helmet>

            <Container>

            </Container>
        </>}
    </>
    )
}